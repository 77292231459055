import store from '@/store';

const themeUrls = store.getters['settings/themeUrls'];

const menu = [
  {
    id: 'calendar',
    name: 'header.events',
    link: `${themeUrls.Events}/events`,
    icon: 'calendar',
    items: [
      {
        name: 'header.upcoming',
        link: `${themeUrls.Events}/events`,
      },
      {
        name: 'header.justNow',
        link: `${themeUrls.Events}/events?e=1`,
        hidden: window.vm?.AnyCurrentEvents !== false,
      },
      {
        name: 'header.series',
        link: `${themeUrls.Events}/events?e=4`,
      },
      {
        name: 'header.virtual',
        link: `${themeUrls.Events}/events?e=3`,
      },
    ],
    open: false,
  },
  {
    id: 'results',
    name: 'header.results',
    link: themeUrls.Results,
    icon: '1st-place-medal',
  },
  {
    id: 'championships',
    name: 'header.championships',
    link: themeUrls.Championships,
    icon: 'trophy',
  },
  {
    id: 'photo',
    name: 'header.photo',
    link: themeUrls.Media,
    icon: 'camera',
  },
  {
    id: 'clubs',
    name: 'header.clubs',
    link: themeUrls.Clubs,
    icon: 'user-groups',
  },
  {
    id: 'journal',
    name: 'header.journal',
    link: 'https://life.russiarunning.com/',
    icon: 'read',
  },
  {
    id: 'for-organizers',
    name: 'header.for_organizers',
    link: `${themeUrls.Events}/Home/About`,
    icon: 'externa-link',
  },
];

const mobileMenu = [
  { isDivider: true },
  {
    name: 'header.my_account',
    link: `${themeUrls.Events}/Profile`,
    icon: 'user',
  },
  {
    name: 'header.organizer_panel',
    link: 'https://admin.russiarunning.com/',
    icon: 'caret-right',
    roles: ['isAdmin', 'isBeneficiary'],
  },
  { isDivider: true },
  {
    name: 'header.help_center',
    link: `${themeUrls.Events}/support`,
    icon: 'safety-float',
  },
  {
    name: 'header.about_platform',
    link: `${themeUrls.Events}/Home/About`,
    icon: 'rocket',
  },
  {
    name: 'header.report_bug',
    action() {
      if (window.Userback) {
        window.Userback.open();
      }
      this.mobileMenuOpen = false;
    },
    icon: 'bug',
  },
];

const profileMenu = [
  {
    name: 'header.profile',
    icon: 'user',
    link: `${themeUrls.Events}/Account`,
  },
  {
    name: 'header.documents',
    icon: 'solution',
    link: `${themeUrls.Events}/MyAccount/admissions`,
  },
  {
    name: 'header.registrations',
    icon: 'badge',
    link: `${themeUrls.Events}/Account/RegistrationList`,
  },
  {
    name: 'header.orders',
    icon: 'shopping-bag',
    link: `${themeUrls.Events}/Account/OrderList`,
  },
  {
    name: 'header.marks',
    icon: 'five-of-five',
    link: `${themeUrls.Events}/Account/MyMarks`,
  },
  {
    name: 'header.my_championships',
    icon: 'trophy',
    link: `${themeUrls.Events}/Account/MyChampionships`,
  },
  {
    name: 'header.teams',
    icon: 'team',
    link: `${themeUrls.Events}/Account/MyTeams`,
  },
  {
    name: 'header.my_clubs',
    icon: 'user-groups',
    link: `${themeUrls.Events}/MyAccount/MyClubs`,
  },
  {
    name: 'header.trainings',
    icon: 'time-limit',
    link: `${themeUrls.Events}/MyAccount/Training`,
  },
  {
    isDivider: true,
    roles: ['isAdmin', 'isBeneficiary'],
  },
  {
    name: 'header.organizers',
    icon: 'caret-right',
    link: 'https://admin.russiarunning.com/',
    roles: ['isAdmin', 'isBeneficiary'],
  },
  {
    name: 'header.bills',
    icon: 'caret-right',
    link: `${themeUrls.Events}/Account/Bills`,
    roles: ['isAdmin'],
  },
  {
    name: 'header.cache',
    icon: 'caret-right',
    link: `${themeUrls.Events}/CacheAdmin`,
    roles: ['isAdmin'],
  },
];

export {
  menu,
  mobileMenu,
  profileMenu,
};
